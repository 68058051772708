import * as Plotly from 'plotly.js';
import { ConditionType, getShortName as getParamName } from '../simData';

/**
 * Generates a plotly "xaxis" object.
 *
 * https://plot.ly/javascript/reference/#layout-xaxis
 */
export const getXAxis = (base?: Partial<Plotly.LayoutAxis>): Partial<Plotly.LayoutAxis> => ({
  color: '#ccc',
  domain: [0, 1],
  showgrid: false,
  showticklabels: false,
  ticks: '',
  type: 'linear',
  ...base,
});

/**
 * Generates a plotly "yaxis" object.
 *
 * https://plot.ly/javascript/reference/#layout-yaxis
 */
export const getYAxis = (
  domain: number[],
  simParam?: string | null,
  base?: Partial<Plotly.LayoutAxis>,
): Partial<Plotly.LayoutAxis> => ({
  domain,
  color: '#ccc',
  gridcolor: '#333',
  showgrid: false,
  showline: true,
  showticklabels: true,
  // @ts-ignore
  title: simParam ? { text: getParamName(simParam) } : null,
  tickcolor: '#888',
  ticklen: 3,
  type: 'linear',
  tickformat: '0f',
  ...base,
});

/**
 * Calculates the length of a string relative to the number of frames drawn
 * in a plot. This is useful to determine the space available to write some text.
 */
const getRelativeTextLength = (string: string, numFrames: number): number => {
  // The estimated number of characters which fit in a plotting area.
  const maxCharLength = 70;

  if (string.length >= maxCharLength) {
    return numFrames;
  }

  // Return the length of the string as a proportion of the total
  // number of frames in the view.
  return Math.round((string.length * numFrames) / maxCharLength);
};

/**
 * Builds a plotly "layout" object. Note that y-axis domain is defined from
 * 0 (bottom) to 1 (top).
 *
 * https://plot.ly/javascript/reference/#layout
 */
const getLayout = (
  simParams: Array<string | string[]>,
  conditions: ConditionType[],
  numFrames: number,
  flaps?: Plotly.Data,
): Partial<Plotly.Layout> => {
  const layout: Partial<Plotly.Layout> = {
    annotations: [],
    autosize: true,
    clickmode: 'none',
    dragmode: false,
    hovermode: false,
    margin: {
      l: 65,
      r: 10,
      t: 20,
      b: 40,
    },
    // TODO: does this "mode" attribute really belong here?
    // @ts-ignore
    mode: 'lines',
    paper_bgcolor: 'transparent',
    plot_bgcolor: 'transparent',
    shapes: [],
    showlegend: true,
  };

  // Start axis numbering at "2"
  let axisNum = 2;
  const plotPadding = 0.05;

  // Dedicate a sub-plot to each condition.
  const condnPlotHeight = 0.05;
  const flapsPlotHeight = 0.1;

  conditions.forEach(({ code, startedAtIndex }, index) => {
    // @ts-ignore
    layout[`xaxis${axisNum}`] = getXAxis({ visible: false });

    // Determine the vertical position of the sub-plot.
    const datum = 1 - index * condnPlotHeight;

    // Build layout options for y-axis.
    // @ts-ignore
    layout[`yaxis${axisNum}`] = getYAxis([datum - condnPlotHeight, datum], null, {
      visible: false,
    });

    const xPaperValue = startedAtIndex / (numFrames - 1);

    layout.shapes && layout.shapes.push({
      xref: 'paper',
      yref: 'paper',
      type: 'line',
      x0: xPaperValue,
      x1: xPaperValue,
      y0: 0,
      y1: datum - 0.0115,
      yanchor: 'top',
      line: {
        color: 'rgba(242, 129, 61, 0.5)',
        width: 2,
      },
    });

    // Add condition name as annotation.
    const conditionTag = getParamName(code);
    const conditionTagLength = getRelativeTextLength(conditionTag, numFrames);

    // If there is space to the left of the beginning of the condition ribbon,
    // show the condition tag there. If not, show it inside the ribbon.
    const isInside = conditionTagLength > startedAtIndex;
    layout.annotations && layout.annotations.push({
      x: Math.max(startedAtIndex, 1) / (numFrames - 1),
      // xanchor: isInside ? 'left' : 'right',
      xanchor: 'left',
      xref: 'paper',
      y: 0,
      yanchor: 'middle',
      xshift: 10,
      // @ts-ignore
      yref: `y${axisNum}`,
      text: conditionTag,
      font: {
        color: isInside ? 'black' : 'black',
      },
      showarrow: false,
    });

    axisNum++;
  });

  // Calculate the height of each sub-plot.
  const plotHeight = flaps
    ? (1 - conditions.length * condnPlotHeight - flapsPlotHeight) / (simParams.length)
    : (1 - conditions.length * condnPlotHeight) / (simParams.length);

  simParams.forEach((param, index) => {
    // Build layout options for x-axis. For the last parameter in the list, we
    // include the options for drawing the x-axis.
    // @ts-ignore
    layout[`xaxis${axisNum}`] = (index < simParams.length - 1)
      ? getXAxis()
      // @ts-ignore
      : getXAxis({
        showticklabels: true,
        tickcolor: '#888',
        ticklen: 3,
        ticks: 'inside',
        anchor: (`y${axisNum}` as Plotly.AxisName),
      });

    // Determine the vertical position of the sub-plot.
    // const datum = 1 -
    const datum = flaps
      ? (simParams.length - index) * plotHeight + flapsPlotHeight
      : (simParams.length - index) * plotHeight;

    // Build layout options for y-axis.
    // @ts-ignore
    layout[`yaxis${axisNum}`] = getYAxis([datum - plotHeight, datum - plotPadding], param);

    axisNum++;
  });

  if (flaps) {
    // @ts-ignore
    layout[`xaxis${axisNum}`] = getXAxis();

    const datum = (simParams.length + 1) * plotHeight * flapsPlotHeight;

    // @ts-ignore
    layout[`yaxis${axisNum}`] = getYAxis(
      [datum - flapsPlotHeight - plotHeight, datum - plotPadding],
      flaps.name,
      {
        autorange: 'reversed',
        showline: false,
        zeroline: false,
        showticklabels: false,
        ticks: '',
      },
    );

    axisNum++;
  }

  return layout;
};

export default getLayout;
