import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

interface CardItem {
  title: React.ReactNode;
  description: React.ReactNode;
}

interface CardProps {
  isActive: boolean;
  activeIndex: number;
  index: number;
  position: number;
  stop?: boolean
}

const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding-top: 50px;
    padding-bottom: 150px;
`;

const Card = styled.div<CardProps>`
  ${({ isActive, position, index, activeIndex, stop }) => `
    display: ${index < activeIndex ? 'none' : 'block'};
    width: 516px;
    height: 80px;
    align-items: flex-start;
    border-radius: 8px;
    border: 1px solid #FFF;
    text-align: center;
    transition: all 0.5s ease;
    gap 8px;
    position: relative;
    transform: ${!stop && `scale(${isActive ? 1.3 : (1 - parseFloat(`0.${index}`))})`};
    opacity: ${!stop && (isActive ? 1 : parseFloat('0.5') - parseFloat(`0.${position}`))};
    background: ${isActive ? '#000' : 'rgba(255, 255, 255, 0.10)'};
    z-index: ${isActive ? 2 : 1};
  `}
`;

interface CardCarouselProps {
  cards: CardItem[];
  interval: number;
  stop?: boolean
}

export default function CardCarousel({ cards, interval, stop }: CardCarouselProps) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [offset, setOffset] = useState(0);

  const isEven = (num: number) => num % 2 === 0;

  const intervalOffset = interval - offset;

  useEffect(() => {
    const passiveTimeout = 5000;
    let timeout: number;

    if (isEven(activeIndex + 1)) {
      timeout = setInterval(() => {
        if (!stop) {
          setOffset(current => current + passiveTimeout);
          setActiveIndex(prevIndex => (prevIndex + 1) % cards.length);
        }
      }, passiveTimeout);
    } else {
      timeout = setInterval(() => {
        if (!stop) {
          setActiveIndex(prevIndex => (prevIndex + 1) % cards.length);
        }
      }, intervalOffset);
    }

    if (stop) {
      clearInterval(timeout);
    }

    return () => clearInterval(timeout);
  }, [cards.length, interval, stop, activeIndex, intervalOffset]);

  if (stop) {
    return null;
  }

  return (
    <div>
      <CardContainer>
        {cards.map((card, index) => {
          const position = (index - activeIndex + cards.length) % cards.length;
          const isActive = index === activeIndex;
          const key = String(index);
          return (
            <Card key={key} isActive={isActive} activeIndex={activeIndex} index={index} position={position} stop={stop}>
              <CardTitle>{card.title}</CardTitle>
              <CardDescription>{card.description}</CardDescription>
            </Card>
          );
        })}
      </CardContainer>
    </div>
  );
}

const CardTitle = styled.h2`
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%; /* 22px */
    align-self: stretch;
`;

const CardDescription = styled.h2`
    color: #D1D1D1;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 17.6px */
    align-self: stretch;
`;
