import * as React from 'react';
import styled from 'styled-components';
import { Button } from '../../../ui/Button';
import { Container, Row } from '../components/grid';
import { DateTime } from '../components/DateTime';
import { FullSessionType } from '../../../session/constants';

export interface VideoPageProps {
  session: FullSessionType
  onEndSession: () => void;
}
export default function VideoPage(props: VideoPageProps) {
  const { session, onEndSession } = props;
  const [videoEnded, setVideoEnded] = React.useState(false);

  const handleVideoEnded = () => {
    setVideoEnded(true);
  };

  return (
    <VideoBg focused={videoEnded}>
      {videoEnded && (
      <TitlePlacement>
        <Container>
          <Row>
            <Item style={{ flexGrow: 1 }}>
              <SessionTitle>
                {session.lessonPlanName}
              </SessionTitle>
            </Item>
            <Item>
              <DateTime dt={session.scheduledFor} />
            </Item>
          </Row>
        </Container>
      </TitlePlacement>
      )}

      <Video id="session-video" width="100%" height="100%" autoPlay onEnded={handleVideoEnded}>
        <source src="/videos/landing-demo.mp4" type="video/mp4" />
        Your browser does not support HTML video.
      </Video>

      {videoEnded && (
      <EndSessionButtonPlacement>
        <Button width="304px" height="64px" onClick={onEndSession}>END SESSION</Button>
      </EndSessionButtonPlacement>
      )}
    </VideoBg>
  );
}

const Video = styled.video`
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  @media only screen and (orientation: portrait) {
    height: 100%;
  }
`;
const VideoBg = styled.section<{focused: boolean}>`
  width: 100%;
  height: calc(100% - 58px);
  overflow: hidden;
  content:"";
  position: absolute;
  top:58px;
  right:0;
  left:0;
  bottom:0;
  transform: translate(0, 0);

  ${props => props.focused && `:before {
    content:"";
    position: absolute;
    top:0;
    right:0;
    left:0;
    bottom:0;
    z-index:1;
    background:linear-gradient(to right,rgba(0, 0, 0, 0.9),rgba(255, 255, 255, 0.1));
  }`}

  @media only screen and (orientation: portrait) {
    height: calc(100% - 60px);
  }
`;

const EndSessionButtonPlacement = styled.div`
  position: fixed;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%, -5%);
  z-index:2;
  @media only screen and (orientation: portrait) {
    bottom: 10%;
    transform: translate(-50%, -10%);
  }
`;

const TitlePlacement = styled.div`
  position: fixed;
  top: 5%;
  left: 0;
  transform: translate(0, -5%);
  width: 100%;
  z-index:2;
`;

const Item = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  gap: 16px;
`;

const SessionTitle = styled.h2`
  flex: 1 0 0;
  color: #FFF;
  font-family: Roboto;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 30.8px */
`;
