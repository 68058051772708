import { FixedMarkers, RunwayLines } from './types';

export const SVGRunway = {
  x0: 290,
  y0: 0,
  height: 700,
  width: 98,
};

export const SVGRunwayCenter = SVGRunway.x0 + SVGRunway.width / 2;
export const defaultStroke = { color: '#979797', width: 2 };

const touchdownZoneMarking = {
  length: 140,
  x: 312,
  topY: 41,
  bottomY: 665.136364,
  numberOfLines: 6,
  lineWidth: 2,
  spacing: 4,
  centerSpacing: 38,
  distToRwyThld: 500,
};

const runwayLine = {
  length: 150,
  x: 299.5,
  margin: 5,
  bottomY: 748.672131,
  numberOfLines: 10,
  lineWidth: 5,
  spacing: 8,
  centerSpacing: 15,
};

export const topTouchdownZoneMarkings = (
  offset: number = 0,
  normalizeFactor: number,
): RunwayLines => {
  const baseY = SVGRunway.y0 + offset;
  return {
    x: touchdownZoneMarking.x,
    y1: baseY + touchdownZoneMarking.distToRwyThld * normalizeFactor,
    y2:
      baseY
      + (touchdownZoneMarking.distToRwyThld + touchdownZoneMarking.length)
        * normalizeFactor,
    numberOfLines: touchdownZoneMarking.numberOfLines,
    lineWidth: touchdownZoneMarking.lineWidth,
    spacing: touchdownZoneMarking.spacing,
    centerSpacing: touchdownZoneMarking.centerSpacing,
  };
};

export const bottomTouchdownZoneMarkings = (
  y: number,
  offset: number = 0,
  normalizeFactor: number,
): RunwayLines => ({
  x: touchdownZoneMarking.x,
  y1:
    SVGRunway.y0
    + y
    + offset
    - (touchdownZoneMarking.distToRwyThld + touchdownZoneMarking.length)
      * normalizeFactor,
  y2:
    SVGRunway.y0
    + y
    + offset
    - touchdownZoneMarking.distToRwyThld * normalizeFactor,
  numberOfLines: touchdownZoneMarking.numberOfLines,
  lineWidth: touchdownZoneMarking.lineWidth,
  spacing: touchdownZoneMarking.spacing,
  centerSpacing: touchdownZoneMarking.centerSpacing,
});

export const topRunwayLines = (
  offset: number = 0,
  normalizeFactor: number,
): RunwayLines => {
  const baseY = SVGRunway.y0 + offset;
  return {
    x: runwayLine.x,
    y1: baseY + runwayLine.margin,
    y2: baseY + runwayLine.length * normalizeFactor,
    numberOfLines: runwayLine.numberOfLines,
    lineWidth: runwayLine.lineWidth,
    spacing: runwayLine.spacing,
    centerSpacing: runwayLine.centerSpacing,
  };
};

export const bottomRunwayLines = (
  y: number,
  offset: number = 0,
  normalizeFactor: number,
): RunwayLines => {
  const baseY = y + offset;
  return {
    x: runwayLine.x,
    y1: baseY - runwayLine.length * normalizeFactor,
    y2: baseY - runwayLine.margin,
    numberOfLines: runwayLine.numberOfLines,
    lineWidth: runwayLine.lineWidth,
    spacing: runwayLine.spacing,
    centerSpacing: runwayLine.centerSpacing,
  };
};

export const runwayNameX = (name: string) => (name.length === 3 ? 322 : 329);
export const runwayNameY = (y: number, offset: number = 0, normalizeFactor: number) => y
  + offset
  - (touchdownZoneMarking.distToRwyThld * normalizeFactor) / 2;

export const oppositeRunwayNameX = (name: string) => (name.length === 3 ? 355 : 350);

export const oppositeRunwayNameY = (offset: number, normalizeFactor: number) => SVGRunway.y0
  + offset
  + touchdownZoneMarking.distToRwyThld * normalizeFactor;

export const runwayCenterLine = (
  start: number = 0,
  end: number,
) => ({
  x: SVGRunwayCenter,
  y1: start,
  y2: end,
  length: 40,
  margin: 40,
});

const fixedDistanceMarker = {
  x1: SVGRunwayCenter - 23,
  x2: SVGRunwayCenter + 23,
  length: 140,
  distToRwyThld: 1000,
  width: 4,
};

export const topFixedDistanceMarkers = (
  offset: number = 0,
  normalizeFactor: number,
): FixedMarkers => {
  const baseY = SVGRunway.y0 + offset;
  return {
    x1: fixedDistanceMarker.x1,
    x2: fixedDistanceMarker.x2,
    y1: baseY + fixedDistanceMarker.distToRwyThld * normalizeFactor,
    y2:
      baseY
      + (fixedDistanceMarker.distToRwyThld + fixedDistanceMarker.length)
        * normalizeFactor,
    width: fixedDistanceMarker.width,
  };
};

export const bottomFixedDistanceMarkers = (
  y: number,
  offset: number = 0,
  normalizeFactor: number,
): FixedMarkers => {
  const baseY = y + offset;
  return {
    x1: fixedDistanceMarker.x1,
    x2: fixedDistanceMarker.x2,
    y1: baseY - fixedDistanceMarker.distToRwyThld * normalizeFactor,
    y2:
      baseY
      - (fixedDistanceMarker.distToRwyThld + fixedDistanceMarker.length)
        * normalizeFactor,
    width: fixedDistanceMarker.width,
  };
};

export const thresholdAreaArrow = {
  height: 10,
  margin: 10,
};
