import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import { useHistory } from 'react-router-dom';

import { lightGrey } from '../../../theme/palette';
import { SimulatorStatus } from '../../../training/types';
import { VerticalDivider } from '../../../ui/Divider';
import NavBar from '../../../ui/NavBar';
import PreviewRibbon from '../../../ui/PreviewRibbon';
import { SimulatorStatusUtil } from '../../../utils/common/simulatorStatusHandler';
import config from '../../../config';

export const reloadHandler = () => window.location.reload(true);

type Props = {
  handleLogout?: Function,
  showEndSession?: boolean,
  onEndSessionRun?: () => void,
  back?: boolean | string | Function,
  isPreview?: boolean,
  simulatorStatus?: SimulatorStatus | null;
  minHeight?: string;
};

const Header = (props: Props) => {
  const { handleLogout, showEndSession, onEndSessionRun, back, isPreview, simulatorStatus, minHeight } = props;
  const [showNavBar, setShowNavBar] = React.useState(false);
  const history = useHistory();

  const handleGoBack = () => {
    if (back === 'schedule') {
      history.push('/schedule');
    } else if (typeof back === 'function') {
      back();
    } else {
      history.goBack();
    }
  };

  return (
    <>
      {isPreview && !showEndSession && (
        <PreviewRibbon />
      )}

      {isPreview && showEndSession && (
        <VerticalBar animate={false} color={lightGrey.string()} top={0}>BETA</VerticalBar>
      )}

      {/* {simulatorStatus && (
        <VerticalBar
          animate={simulatorStatus === SimulatorStatus.CONNECTED}
          color={SimulatorStatusUtil.getColor(simulatorStatus)}
          top={isPreview && showEndSession ? 20 : 0}
        >
          {SimulatorStatusUtil.getMessage(simulatorStatus)}
        </VerticalBar>
      )} */}

      <NavBar showNavBar={showNavBar} handleLogout={handleLogout} />
      <Background hidden={!showNavBar} onClick={() => setShowNavBar(!showNavBar)} />

      <Wrapper isPreview={isPreview} live={showEndSession} isSimulatorStatus={!!simulatorStatus} minHeight={minHeight}>

        <NavBarFlex>
          <div>
            {handleLogout && (
            <MenuIcon width="30px" onClick={() => setShowNavBar(!showNavBar)} />
            )}

            {back && (
            <BackIcon
              onClick={handleGoBack}
              hidden={!back}
            />
            )}
          </div>

          <LogoWrapper onClick={reloadHandler}>
            <LogoInstructIQ />
          </LogoWrapper>

          <VerticalDivider color="#fff" height={28} width={1} />

          <LogoWrapper onClick={reloadHandler}>
            <LogoPace />
            <PaceLeonardoSeparator>&</PaceLeonardoSeparator>
            <LogoLeonardo />
          </LogoWrapper>
        </NavBarFlex>
        <EndSession
          onClick={onEndSessionRun}
          hidden={!showEndSession}
        >
          End Session
        </EndSession>

      </Wrapper>
    </>
  );
};

export default Header;

Header.defaultProps = {
  handleLogout: null,
  showEndSession: false,
  onEndSessionRun: null,
  back: null,
  isPreview: config.deployEnv === 'preview',
  simulatorStatus: null,
};

const ProgressBarStripesAnimation = keyframes`
  from { background-position: 1rem 0; }
  to { background-position: 0 0; }
`;

const VerticalBar = styled.div<{ color: string, top: number, animate: boolean }>`
  background-color: ${props => props.color};
  position: fixed;
  width: 100%;
  top: ${props => props.top}px;
  height: 20px;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  z-index: 12;
  ${({ animate }) => animate && `
    background-image: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
  `}
  -webkit-background-size: 1rem 1rem;
  background-size: 1rem 1rem;
  animation-name: ${props => (props.animate ? ProgressBarStripesAnimation : '')};
  animation-duration: 1s;
  animation-iteration-count: infinite;
`;

function calcWrapperHeight(isPreview?: boolean, live?: boolean, isSimulatorStatus?: boolean): string {
  let result = 0;
  if (isPreview && live) {
    result += 20;
  }
  if (isSimulatorStatus) {
    result += 20;
  }
  return `${result}px`;
}

const Wrapper = styled.header<{ isPreview?: boolean, live?: boolean, isSimulatorStatus?: boolean, minHeight?: string }>`
  background-color: ${props => props.theme.black.string()};
  color: ${props => props.theme.white.string()};
  font-size: 1.2em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: ${props => (props.minHeight ? 0 : (calcWrapperHeight(props.isPreview, props.live, props.isSimulatorStatus)))};
  box-sizing: border-box;
  width: 100%;
  min-height: ${props => (props.isPreview && props.live ? '65px' : props.minHeight || props.theme.headerHeight)};
  padding: 0 25px;
  z-index: 11;
`;

const LogoWrapper = styled.button`
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0;
`;

export const Logo = styled.div`
  background: center center transparent no-repeat url('/logos/logo-paladin.svg');
  background-size: contain;
  display: inline-block;
  position: fixed;
  left: 50%;
  margin-left: -130px;
  height: 61px;
  width: 259px;
`;

export const LogoInstructIQ = styled.div`
  background: center center transparent no-repeat url('/logos/logo-instructiq.svg');
  background-size: contain;
  width: 194px;
  height: 49px;
  @media (max-width: 600px) {
    width: 184px;
    height: 39px;
  }
`;
export const LogoPace = styled.div`
  background: center center transparent no-repeat url('/logos/logo-pace.svg');
  background-size: contain;
  width: 78.588px;
  height: 24px;
  @media (max-width: 600px) {
    width: 68.588px;
  }
`;
export const LogoLeonardo = styled.div`
  background: center center transparent no-repeat url('/logos/logo-leonardo.svg');
  background-size: contain;
  width: 101.25px;
  height: 18px;
  @media (max-width: 600px) {
    width: 91.25px;
  }
`;
export const PaceLeonardoSeparator = styled.p`
  color: #fff;
  opacity: 0.6;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 100;
  line-height: normal;
  letter-spacing: 0.36px;
  text-transform: uppercase;
  margin-left: 8px;
  margin-right: 8px;
`;

const EndSession = styled.div`
  font-size: 16px;
  line-height: 19px;
  color: #30AEFF;
  cursor: pointer;
`;

const BackIcon = styled.div`
  background: center center transparent no-repeat url(/icons/BackArrow.svg);
  background-size: contain;
  height: 20px;
  width: 26px;
`;

const MenuIcon = styled.div<{ height?: string, width: string }>`
  background: center center transparent no-repeat url(/icons/menu.png);
  background-size: contain;
  height: ${props => props.height || props.width || '100%'};
  width: ${props => props.width || props.height || 'auto'};
  @media (max-width: 600px) {
    width: 20px;
  }
`;

const Background = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.4;
  z-index: 12;
`;

// const UserIcon = styled.div`
//   background: center center transparent no-repeat url('${userIcon}');
//   background-size: contain;
//   display: inline-block;
//   height: ${props => props.height || props.width || '100%'};
//   width: ${props => props.width || props.height || 'auto'};
// `;

const NavBarFlex = styled.nav`
  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content: flex-start;
  align-items: center;
`;
