import React from 'react';
import styled from 'styled-components';
import { GradeColumn, LabelColumn, Row, SectionTitle } from '../../../summary/components/TaskList';
import { gradeLevel5, gradeLevel9, lightestGrey, lightGrey, mediumGrey } from '../../../theme/palette';
import { Carat } from './CompetencyRadarPlot';
import { Button, BUTTON_LIGHT } from '../../../ui/Button';
import { CompetencyDrilldownProps, DrilldownObjectType } from '../../constants';

const CompetencyDrilldown = ({
  name,
  norms,
  activities,
  onShowGradeContext,
  onCloseDrilldown,
}: CompetencyDrilldownProps) => {
  const [openActivity, setOpenActivity] = React.useState<string | null>(null);

  const getActivityName = (code: string) => {
    const activity = activities.find(({ activityCode }) => activityCode === code);

    return activity ? activity.name : code;
  };

  const drilldown: DrilldownObjectType = {};

  norms.forEach(norm => {
    if (drilldown[norm.activityCode]) {
      // eslint-disable-next-line no-shadow
      const normIndex = drilldown[norm.activityCode].activityNorms.findIndex(({ name }) => name === norm.normName);
      const activityId = drilldown[norm.activityCode].activityIds.find(id => id === norm.activityId);

      if (normIndex >= 0) {
        drilldown[norm.activityCode].activityNorms[normIndex].pass.push(norm.pass);
      } else {
        drilldown[norm.activityCode].activityNorms.push({
          name: norm.normName,
          pass: [norm.pass],
        });
      }

      norm.pass
        ? drilldown[norm.activityCode].pass += 1
        : drilldown[norm.activityCode].fail += 1;
      !activityId && drilldown[norm.activityCode].activityIds.push(norm.activityId);
    } else {
      drilldown[norm.activityCode] = {
        activityName: getActivityName(norm.activityCode),
        activityIds: [norm.activityId],
        activityNorms: [{ name: norm.normName, pass: [norm.pass] }],
        pass: norm.pass ? 1 : 0,
        fail: !norm.pass ? 1 : 0,
      };
    }
  });

  const handleShowNorms = (activityCode: string) => {
    activityCode !== openActivity ? setOpenActivity(activityCode) : setOpenActivity(null);
  };

  return (
    <>
      <CompetencyDrilldownBackground>
        <CompetencyDrilldownWrapper>
          <CompetencyDrilldownTitle>
            <div>{name || 'Competency Drill-down'}</div>
            <Close onClick={onCloseDrilldown} />
          </CompetencyDrilldownTitle>
          {norms.length >= 1 ? Object.entries(drilldown).map((section, index) => {
            const showLine = index !== Object.keys(drilldown).length - 1;
            const showNorms = section[0] === openActivity;

            return (
              <ActivityWrapper line={showNorms && showLine} key={section[0]}>
                <Row line={showNorms || showLine} onClick={() => handleShowNorms(section[0])}>
                  <LabelColumn>{section[1].activityName === 'APP_FINAL' ? 'Final Approach' : section[1].activityName}</LabelColumn>
                  <GradeColumn>
                    <LargeDot pass={section[1].pass > section[1].fail} />
                  </GradeColumn>
                  <Carat open={showNorms} drilldown />
                </Row>
                <NormsWrapper open={showNorms}>
                  <NormTitle>
                    <div>Norms</div>
                    <Button
                      onClick={() => onShowGradeContext(section[1].activityIds[0])}
                      width="124px"
                      height="40px"
                      margin="none"
                      variation={BUTTON_LIGHT}
                    >
                      MORE DETAILS
                    </Button>
                  </NormTitle>
                  {/* eslint-disable-next-line no-shadow */}
                  {section[1].activityNorms.map((norm, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <NormRow line={index !== section[1].activityNorms.length - 1} key={`norm-${index}`}>
                      <LabelColumn>{norm.name}</LabelColumn>
                      <NormColumn>
                        {/* eslint-disable-next-line no-shadow */}
                        {norm.pass.map((value, index) => {
                          // @ts-ignore
                          const passBoolean = typeof value === 'string' ? value.toLowerCase() === 'true' : Boolean(value);
                          return (
                          // eslint-disable-next-line react/no-array-index-key
                            <Dot pass={passBoolean} key={`dot-${index}`} />
                          );
                        })}
                      </NormColumn>
                    </NormRow>
                  ))}
                </NormsWrapper>
              </ActivityWrapper>
            );
          })
            : (
              <ActivityWrapper>
                <Row>
                  <LabelColumn>No norms available.</LabelColumn>
                </Row>
              </ActivityWrapper>
            )}
        </CompetencyDrilldownWrapper>
      </CompetencyDrilldownBackground>

    </>
  );
};

export default CompetencyDrilldown;

const CompetencyDrilldownBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgb(0, 0, 0, 0.7);
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const CompetencyDrilldownWrapper = styled.div`
  width: calc(100% - 50px);
  max-height: 770px;
  margin: auto;
  background-color: ${mediumGrey.string()};
  border-radius: 5px;
`;

const CompetencyDrilldownTitle = styled(SectionTitle)`
  display: flex;
  justify-content: space-between;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

const ActivityWrapper = styled.div<{ line?: boolean }>`
  display: flex;
  flex-direction: column;
  border-bottom: ${props => (props.line ? `1px solid ${lightGrey.string()}` : '')};
`;

const NormsWrapper = styled.div<{ open?: boolean }>`
  width: calc(100% - 50px);
  margin: ${props => (props.open ? '15px auto' : '0 auto')};
  background-color: ${lightGrey.string()};
  border-radius: 3px;
  max-height: ${props => (props.open ? '300px' : '0')};
  visibility: ${props => (props.open ? 'visible' : 'hidden')};
`;

const NormTitle = styled(CompetencyDrilldownTitle)`
  padding: 5px 5px 5px 20px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  line-height: 40px;
`;

const NormRow = styled(Row)`
  padding: 10px 20px;
  border-bottom: ${props => (props.line ? `1px solid ${lightestGrey.string()}` : '')};
`;

const NormColumn = styled(GradeColumn)`
`;

const Dot = styled.div<{ pass: boolean }>`
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: ${props => (props.pass ? gradeLevel9.string() : gradeLevel5.string())};
  margin: 0 7.5px;
`;

const LargeDot = styled.div<{ pass: boolean }>`
  height: 30px;
  width: 30px;
  background-color: ${props => (props.pass ? gradeLevel9.string() : gradeLevel5.string())};
  border-radius: 50%;
`;

const Close = styled.div`
  cursor: pointer;

  &:after {
    color: white;
    content: "✕";
    font-size: 24px;
  }
`;
