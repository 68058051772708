import React from 'react';
import styled from 'styled-components';

import { getWindBox } from '../Landing/utils';

type WindBoxProps = {
  windSpeed: number,
  windDirection: number,
  relWindDirection: number,
  data: { rwyContaminantType: number, massAc: number, flapLeverPos: number },
};

type Props = WindBoxProps & {
  children: React.ReactNode;
};

const WindBoxSVG = (props: Props) => {
  const { windSpeed, windDirection, relWindDirection, data } = props;

  return (
    <>
      <WindBoxWrapper>
        {props.children}
        <WindBox>
          {getWindBox(windSpeed, windDirection, relWindDirection, data)}
        </WindBox>
      </WindBoxWrapper>
    </>
  );
};

const WindBoxWrapper = styled.div`
  position: relative;
`;
const WindBox = styled.svg`
  position: absolute;
  right: 0;
  top: 32px;
  min-height: 240px;
  width: 240px;
`;

export default WindBoxSVG;
