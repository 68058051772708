import moment from 'moment';
import { all, call, Effect, put, take, select } from 'redux-saga/effects';

import api from '../api';
import * as actions from './actions';
import * as trainingActions from '../training/actions';
import * as dashboardActions from '../dashboard/actions';
import * as activityActions from '../activities/actions';
import * as gradingActions from '../summary/actions';
import * as sessionListActions from '../sessionList/actions';
import { FullSessionType, SessionRunType } from './constants';
import {
  activeRunIds,
  currentSessionId,
  currentSessionActiveRunId,
  currentEquipmentDeviceId,
} from './selectors';
import { liveSessionRun } from '../utils/routes';
// import { DASHBOARD_LOADED } from '../dashboard/actions';

/**
 * Retrieves a full session record, along with it's planned session runs.
 */
type ExpectsSessionData =
  | trainingActions.TrainingScreenLoadedAction
  | gradingActions.GradingScreenAction;
type FetchSessionReturnType = [FullSessionType | null, SessionRunType[]];
type FetchSessionType = Generator<any, FetchSessionReturnType, any>;
type FetchSessionListType = Generator<any, FetchSessionReturnType[], any>;

export function* fetchSessionData(
  action: ExpectsSessionData,
): FetchSessionType {
  const { sessionId } = action.payload;

  try {
    // Determine which relations to expand.
    const sessionExpand = ['equipment', 'lesson_plan.course'];
    const runsExpand = ['pilot_flying', 'pilot_monitoring'];

    if (
      action.type === gradingActions.GRADING_SCREEN_LOADED
      && action.payload.fetchGradingSheet
    ) {
      sessionExpand.push('lesson_plan.grading_sheet');
      runsExpand.splice(0, 1, 'activities.grades');
    }

    // Retrieve session data.
    const [fullSession, { results: sessionRuns }] = yield all([
      call(api.sessions.get, sessionId, sessionExpand),
      call(api.sessions.getRuns, sessionId, runsExpand),
    ]);

    return [fullSession, sessionRuns] as FetchSessionReturnType;
  } catch (error) {
    yield call(api.logError, error);

    return [null, []] as FetchSessionReturnType;
  }
}

function* fetchActivityOptions({ id }: FullSessionType) {
  try {
    return yield call(api.sessions.getActivityOptions, id);
  } catch (error) {
    api.logError(error);

    return [];
  }
}

function* fetchEquipmentRunningSessions(
  equipmentId: number | undefined,
): FetchSessionListType {
  try {
    if (!equipmentId) {
      throw new Error('No equipment id');
    }
    // @ts-ignore
    return yield call(
      api.sessions.getRunningSessionsByEquipmentId,
      equipmentId,
    );
  } catch (error) {
    api.logError(error);

    return [];
  }
}

function* endActiveSessions(
  runIds: number[],
  { history }: { history: History },
) {
  try {
    const requests = runIds.map(x => call(api.sessions.endRun, x, moment().toISOString()));
    const res: Array<{ status: number }> = yield all(requests);

    if (res.every(x => x.status === 204)) {
      yield put(actions.activeSessionsEnded(history));
    }
  } catch (error) {
    api.logError(error);
  }
}

function* startSession({ history }: any) {
  try {
    const sessionId: number | null = yield select(currentSessionId);
    const activeRunId: number | null = yield select(currentSessionActiveRunId);
    if (sessionId && activeRunId) {
      history.push(liveSessionRun(sessionId, activeRunId));
    }
  } catch (error) {
    api.logError(error);
  }
}

export default [
  function* (): Generator<Effect, void, any> {
    while (true) {
      const action = yield take([
        trainingActions.TRAINING_SCREEN_LOADED,
        sessionListActions.SESSION_OVERVIEW_LOADED,
      ]);

      if (action.type === trainingActions.TRAINING_SCREEN_LOADED) {
        yield call(
          api.sessions.startRun,
          action.payload.runId,
          moment().toISOString(),
        );
      }

      const [fullSession, sessionRuns] = yield call(fetchSessionData, action);

      if (fullSession) {
        const activityOptions = yield call(fetchActivityOptions, fullSession);

        yield put(activityActions.setActivityOptions(activityOptions));
      }

      // Store session meta data.
      yield all([
        put(actions.updateSession(fullSession)),
        put(actions.updateRuns(sessionRuns)),
        put(dashboardActions.equipmentDetailsUpdated([fullSession.equipment])),
      ]);
    }
  },

  function* (): Generator<Effect, void, any> {
    while (true) {
      const action = yield take([actions.END_ACTIVE_SESSIONS]);

      if (action.type === actions.END_ACTIVE_SESSIONS) {
        const runIds = yield select(activeRunIds);
        yield call(endActiveSessions, runIds, action.payload);
      }
    }
  },

  function* (): Generator<Effect, void, any> {
    while (true) {
      const action = yield take([
        actions.ACTIVE_SESSIONS_ENDED,
        actions.FETCH_CURRENT_EQUIPMENT_ACTIVE_RUNS,
      ]);

      if (action.type === actions.ACTIVE_SESSIONS_ENDED) {
        yield call(startSession, action.payload);
      }

      if (action.type === actions.FETCH_CURRENT_EQUIPMENT_ACTIVE_RUNS) {
        const equipmentId = yield select(currentEquipmentDeviceId);
        const sessionId: number | null = yield select(currentSessionId);
        const { cb } = action.payload;

        const runningSessions = yield call(
          fetchEquipmentRunningSessions,
          equipmentId,
        );

        if (runningSessions && (runningSessions.results as FullSessionType[])) {
          const activeRunsId: number[] = runningSessions.results
            .filter((x: FullSessionType) => x.id !== sessionId)
            .map((x: FullSessionType) => x.plannedRuns)
            .flat() || [];

          if (activeRunsId.length) {
            yield put(actions.setCurrentEquipmentActiveRunsIds(activeRunsId, !activeRunIds.length));
          } else {
            cb();
          }
        }
      }
    }
  },
];
