import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { NavLink, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from '../../../ui/headers/Header';
import PageHeader from '../../../ui/PageHeader';
import {
  Button,
  BUTTON_BACKGROUND_GRADIENT,
  Wrapper as ButtonWrapper,
  BUTTON_BORDER_DISABLED,
} from '../../../ui/Button';
import {
  accentBlue,
  lightestGrey,
  lightGrey,
  mediumGrey,
} from '../../../theme/palette';
import { SessionOverviewPropsType } from './index';
import Loading from '../../../ui/Loading';
import {
  gradingSheet,
  liveSessionRun,
  traineeProfile,
  viewSession,
} from '../../../utils/routes';
import * as utils from '../../../utils';
import { Trainee } from '../../../ui/TraineeCard';
import ContentContainer from '../../../ui/ContentContainer';
import ClassifierProgress from './ClassifierProgress';
import { hasSessionsExceededDuration } from '../../../session/utils';
import { StartDemoPage } from '../../../demo/pace-leonardo/start';
import { getTrainingCenter } from '../../../dashboard/selectors';
import { CalculatingDemoPage } from '../../../demo/pace-leonardo/calculating';

const SessionOverview = ({
  match,
  currentSession,
  runs,
  sessions,
  isLoading,
  equipment,
  onComponentMounted,
  classifierStatus,
  currentEquipmentActiveRunIds,
  onEndActiveSessions,
  fetchCurrentEquipmentActiveRuns,
  canStartSession,
}: SessionOverviewPropsType) => {
  const history = useHistory();

  const { trainingCenter } = useSelector(getTrainingCenter);

  let sessionEnded = true;
  const sessionId = parseInt(match.params.sessionId, 10);

  useEffect(() => {
    onComponentMounted(sessionId);
  }, [onComponentMounted, sessionId]);

  const [reviewDisabled, setReviewDisabled] = useState(true);

  useEffect(() => {
    setReviewDisabled(classifierStatus && classifierStatus.progress !== 1.0);
    if (!classifierStatus) {
      return;
    }
    if (classifierStatus.progress === 1) {
      setReviewDisabled(false);
    }
  }, [classifierStatus]);

  useEffect(() => {
    if (canStartSession) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      history.push(btnRoute);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canStartSession]);

  const endRunningEquipmentSessions = () => {
    if (
      // eslint-disable-next-line no-alert
      window.confirm(
        `There are active sessions running on selected equipment - starting this session will end those active sessions.
Are you sure you want to continue?`,
      )
    ) {
      onEndActiveSessions();
    }
  };

  useEffect(() => {
    if (currentEquipmentActiveRunIds.length) {
      endRunningEquipmentSessions();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEquipmentActiveRunIds]);

  if (
    Object.values(currentSession).length < 1
    || runs.length < 1
    || equipment.length < 1
    || isLoading
  ) {
    return <Loading />;
  }

  const date = moment(String(currentSession.scheduledFor)).format(
    'MMMM Do, h:mm A',
  );
  const course = currentSession.courseName || '';
  const lessonPlan = currentSession.lessonPlanName || currentSession.name || '';
  const aircraft = equipment[0].aircraftDetails.aircraft[0].designator;
  const device = equipment[0].aircraftDetails.aircraft[0].name;

  const sessionIndex = sessions.findIndex(({ id }) => id === sessionId);
  const session = sessions[sessionIndex];

  let btnText = 'START PART 1';
  let disabled = false;
  let btnRoute = liveSessionRun(
    session.id,
    (session.plannedRuns && session.plannedRuns[0]) || 0,
  );
  let sessionTimeExceeded = false;

  if (utils.isRunning(session)) {
    btnText = 'START PART 2';
    disabled = true;
    btnRoute = liveSessionRun(session.id, session.activeRunId);
    sessionEnded = false;
  } else if (utils.isEnded(session)) {
    btnText = 'REVIEW';
    disabled = true;
    btnRoute = gradingSheet(session.id);
    sessionTimeExceeded = hasSessionsExceededDuration(session);
  } else if (session.plannedRuns && session.plannedRuns[0]) {
    btnText = 'START PART 1';
    disabled = false;
    btnRoute = liveSessionRun(session.id, session.plannedRuns[0]);
    sessionEnded = false;
  } else {
    return null;
  }

  const handleButtonClick = () => {
    if (utils.isEnded(session)) {
      history.push(btnRoute);
    } else {
      fetchCurrentEquipmentActiveRuns(() => history.push(btnRoute));
    }
  };

  return (
    <>
      <Header back="schedule" />

      {trainingCenter === 'PACE' ? (
        <>
          {sessionEnded
            ? <CalculatingDemoPage session={session} onReview={handleButtonClick} />
            : <StartDemoPage session={session} onStartSession={handleButtonClick} />}
        </>
      ) : (
        <>
          <PageHeader title={course} />
          <ContentContainer>
            <ButtonWrapper>
              <MarginTop>
                {/* <NavLink to={btnRoute}> */}
                <Button
                  width="290px"
                  margin="auto"
                  marginBottom="0px"
                  variation={
                    reviewDisabled
                      ? BUTTON_BORDER_DISABLED
                      : BUTTON_BACKGROUND_GRADIENT
                  }
                  backgroundColor="#000000"
                  disabled={sessionEnded && reviewDisabled}
                  onClick={handleButtonClick}
                >
                  {btnText}
                </Button>
                {/* </NavLink> */}
                {!sessionEnded
                  && currentEquipmentActiveRunIds
                  && !!currentEquipmentActiveRunIds.length && (
                    <EndActiveSessionsButton
                      onClick={endRunningEquipmentSessions}
                    />
                )}
              </MarginTop>
            </ButtonWrapper>

            {classifierStatus && (
              <ClassifierProgress progress={classifierStatus.progress} />
            )}
            {sessionTimeExceeded
              && (
              <SessionTimeExceeded>
                The maximum duration for a session has been exceeded.
                Flight analytics and assessments will be limited to the first 6 hours of this session
              </SessionTimeExceeded>
              )}
            <Wrapper>
              <Section>
                <Tile>
                  <Title>
                    Lesson Plan
                    <NavLink to={viewSession(session.id)}>
                      <EditButton disabled={disabled}>Edit</EditButton>
                    </NavLink>
                  </Title>
                  <Content>{lessonPlan}</Content>
                </Tile>
                <Tile>
                  <Title>Pilots</Title>
                  <PilotContent>
                    <NavLink to={traineeProfile(session.trainees.pilotFlying.id)}>
                      <Trainee
                        trainee={session.trainees.pilotFlying.name}
                        hideLine
                      />
                    </NavLink>
                    {session.trainees.pilotMonitoring && (
                      <>
                        <Line />
                        <NavLink
                          to={traineeProfile(session.trainees.pilotMonitoring.id)}
                        >
                          <Trainee
                            trainee={session.trainees.pilotMonitoring.name}
                            hideLine
                          />
                        </NavLink>
                      </>
                    )}
                  </PilotContent>
                </Tile>
              </Section>
              <Section>
                <Tile>
                  <Title>Date Scheduled</Title>
                  <Content>{date}</Content>
                </Tile>
                <Tile>
                  <Title>Aircraft Type</Title>
                  <Content>{aircraft}</Content>
                </Tile>
                <Tile>
                  <Title>Device</Title>
                  <Content>{device}</Content>
                </Tile>
              </Section>
            </Wrapper>
          </ContentContainer>
        </>
      )}

    </>
  );
};

export default SessionOverview;

const Wrapper = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: space-evenly;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
`;

const Tile = styled.div`
  background-color: ${mediumGrey.string()};
  border-radius: 5px;
  margin-bottom: 20px;
`;

const Title = styled.div`
  font-size: 16px;
  color: ${lightestGrey.string()};
  line-height: 19px;
  padding: 15px 20px;
  border-bottom: 1px solid ${lightGrey.string()};
  display: flex;
  justify-content: space-between;
  font-weight: 400;
`;

const EditButton = styled.div<{ disabled: boolean }>`
  font-size: 16px;
  color: ${accentBlue.string()};
  font-weight: 400;
  visibility: ${props => (props.disabled ? 'hidden' : 'visible')};
`;

const Content = styled.div`
  padding: 20px;
  font-weight: 400;
`;

const PilotContent = styled(Content)`
  padding: 20px 0;
`;

const Line = styled.div`
  width: 100%;
  margin: 20px 0;
  border-bottom: 1px solid ${lightGrey.string()};
`;

const MarginTop = styled.div`
  margin-top: 165px;
  position: relative;
`;

const EndActiveSessionsButton = styled.div`
  position: absolute;
  width: 100%;
  height 100%;
  left: 0;
  top: 0;
  background: transparent;
  cursor: pointer;
`;

const SessionTimeExceeded = styled.p`
  text-align: center;
`;
