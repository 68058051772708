import { eventChannel, Task } from 'redux-saga';
import {
  call,
  cancelled,
  fork,
  put,
  select,
  take,
  takeEvery,
  Effect,
  takeLatest,
} from 'redux-saga/effects';

import moment from 'moment';
import * as actions from './actions';
import { getPollingSimulatorStatusSubscriber } from './subscribers/polling';
import { getRunId } from './selectors';
import { SimulatorStatusResponse } from './types';
import api from '../api';
import * as frameActions from '../frames/actions';

const createLiveSimulatorStatusChannel = (runId: number) => eventChannel(getPollingSimulatorStatusSubscriber(runId));

/**
 * Listens on the event channel and passes on live session data to the
 * Redux store.
 */
function* liveSessionSimulatorStatusListener(): Generator<Effect, void, any> {
  const runId = yield select(getRunId);
  const liveSessionChannel = yield call(createLiveSimulatorStatusChannel, runId);

  try {
    while (true) {
      const simulatorStatus: SimulatorStatusResponse = yield take(liveSessionChannel);

      yield put(actions.trainingSessionSimulatorStatusEvent(simulatorStatus));
    }
  } finally {
    if (yield cancelled()) {
      liveSessionChannel.close();
    }
  }
}

/**
 * Records an event.
 */
function* recordEvent({ payload }: actions.RecordEventAction) {
  const { sessionRunId, eventType, data } = payload;

  try {
    const event = {
      data,
      eventType,
      sessionRunId,
      timestamp: moment().toISOString(),
    };

    // @ts-ignore
    yield call(api.events.create, event);
  } catch (error) {
    api.logError(error);
  }
}

/**
 * Marks a session run as ended.
 */
function* endRun({ payload }: actions.EndRunAction) {
  const { id, callback } = payload;

  try {
    yield call(api.sessions.endRun, id, moment().toISOString());
    yield put(frameActions.clearFrames());

    if (typeof callback === 'function') {
      yield call(callback);
    }
  } catch (error) {
    api.logError(error);
  }
}

/**
 * Live session watchers.
 */
export default {
  // Determines when to start and stop listening for live session data.
  * watchTrainingSession(): Generator<Effect, void, any> {
    while (true) {
      const { type, payload } = yield take([actions.TRAINING_SCREEN_LOADED]);
      const isActive = type === actions.TRAINING_SCREEN_LOADED;

      yield put(actions.trainingSessionActive(isActive, payload.runId));
    }
  },

  // Updates live session data as it comes into the app.
  * watchLiveSessionListener(): Generator<Effect, void, any> {
    let simulatorStatuslistener: Task | null = null;

    while (true) {
      const { type } = yield take([actions.TRAINING_SESSION_STARTED, actions.TRAINING_SESSION_ENDED]);

      if (simulatorStatuslistener) {
        simulatorStatuslistener.cancel();
        simulatorStatuslistener = null;
      }

      if (type === actions.TRAINING_SESSION_STARTED && !simulatorStatuslistener) {
        simulatorStatuslistener = yield fork(liveSessionSimulatorStatusListener);
      }
    }
  },

  * watchRecordEvent(): Generator<Effect, void, void> {
    yield takeEvery(actions.RECORD_EVENT, recordEvent);
  },

  * watchEndRun(): Generator<Effect, void, void> {
    yield takeLatest(actions.END_RUN, endRun);
  },
};
