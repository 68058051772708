import React, { useState } from 'react';
import styled from 'styled-components';
import { lightGrey } from '../../theme/palette';
import IconMagnify from '../../ui/svg/icon-magnify.svg';
import NormSummaryGraph from '../plotly/normSummaryGraph';
import { NormType } from '../visualization/constants';
import { FrameType } from '../../frames/constants';
import { NormSummaryAnnotation } from '../plotly/annotations/types';

const NormRow = (props: {
  norm: NormType & { frames: FrameType[], annotation: NormSummaryAnnotation[] };
  expand: boolean;
  onExpand: Function;
  children: any;
}) => {
  const [showElement, setShowElement] = useState(false);
  const { norm, expand, onExpand } = props;

  const handleToggle = () => {
    const show = !showElement;
    setShowElement(show);

    if (show) {
      onExpand();
    }
  };

  return (
    <div>
      <Norm>
        {props.children}
        {expand && <MagnifyIcon onClick={handleToggle} />}
      </Norm>

      {expand && showElement && (
        <div>
          <NormSummaryGraph norm={norm} />
        </div>
      )}
    </div>
  );
};

export default NormRow;

const Norm = styled.div`
  display: flex;
  line-height: 60px;
  border-top: 1px solid ${lightGrey.string()};
  font-size: 16px;
  position: relative;
`;

const MagnifyIcon = styled.div`
  background: center center transparent no-repeat url("${IconMagnify}");
  height: 32px;
  width: 32px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  cursor: pointer;
`;
