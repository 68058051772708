import * as React from 'react';
import styled from 'styled-components';
import Loading from '../../../ui/Loading';
import { mediumGrey } from '../../../theme/palette';
import { LessonPlanType } from '../../types';
import { Button } from '../../../ui/Button';

type Props = {
  currentRunId: number,
  lessonPlan: Record<number, LessonPlanType[]>,
  onEndSessionRun: () => void
};

const LessonPlan = ({ currentRunId, lessonPlan, onEndSessionRun }: Props) => {
  if (!currentRunId || !lessonPlan[currentRunId]) {
    return <Loading />;
  }

  return (
    <Wrapper>
      <Shade key="shade" />
      <Padding key="padding" />
      {(lessonPlan[currentRunId])
        .sort((a, b) => a.ordering - b.ordering)
        .map(x => (
          // eslint-disable-next-line react/no-array-index-key
          <LessonPlanActivityWrapper key={x.ordering}>
            <LessonPlanActivityHeading>
              {x.name}
            </LessonPlanActivityHeading>
          </LessonPlanActivityWrapper>
        ))}

      <ButtonWrapper>
        <Button
          width="150px"
          margin="auto"
          onClick={onEndSessionRun}
        >
          END SESSION
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default LessonPlan;

const Wrapper = styled.section`
  position: relative;
  top: 200px;
`;

const Shade = styled.div`
  background: linear-gradient(black, transparent);
  display: block;
  position: fixed;
  width: 100vw;
  height: 60px;
`;

const Padding = styled.div`
  display: block;
  height: 80px;
`;

const LessonPlanActivityWrapper = styled.div`
  width: 658px;
  margin: 10px auto;
  user-select: none;
  border: 2px solid transparent;
  border-radius: 5px;
`;

const LessonPlanActivityHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 60px;
  padding: 0px 5px 0 20px;
  user-select: none;
  background: ${mediumGrey.string()};
  border: 4px solid transparent;
  border-radius: 2px;
  color: ${props => props.theme.textColor.darken(0.4).string()};
  justify-content: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 23px auto;
`;
