import * as React from 'react';
import styled from 'styled-components';

const Divider = styled.div<{color?: string; width?: number; height?: number, responseive?: boolean;}>`
    position: relative;
    background-color: ${props => props.color || 'inherit'};
    width: ${props => (props.width ? `${props.width}px` : '1px')};
    height: ${props => (props.height ? `${props.height}px` : '100vh')};
    opacity: 0.8;
    margin-top: 15px;
    margin-bottom: 15px;
    ${props => props.responseive && `@media (max-width: 900px) {
      width: 100% !important;
      height: 1px !important;
    }`}
`;

export interface VerticalDividerProps extends React.HTMLAttributes<HTMLDivElement> {
  width?: number;
  height?: number;
  color?: string;
  responseive?: boolean;
}

export default function VerticalDivider(props:VerticalDividerProps) {
  return <Divider {...props} />;
}
