import React from 'react';
import styled from 'styled-components';
import {
  gradeLevel5,
  gradeLevel9,
  lightGrey,
  mediumGrey,
  white,
} from '../../theme/palette';
import { SectionTitle } from '../../summary/components/TaskList';
import { NormType, NormVisualizationType } from '../visualization/constants';
import { NormCodeToNameMap, NormOrderingFromCodeMap } from './constants';
import NormRow from './NormRow';
import { FrameType } from '../../frames/constants';
import { NormSummaryAnnotation } from '../plotly/annotations/types';

// @ts-ignore
const NormSummary = data => {
  const norms: Array<
  NormVisualizationType &
  NormType & { frames: FrameType[]; annotation: NormSummaryAnnotation[] }
  > = [];
  const { frames, expand } = data as {
    frames: FrameType[];
    expand: boolean;
    annotation: NormSummaryAnnotation[];
  };
  data.payload.forEach((norm: NormType) => {
    NormCodeToNameMap.get(norm.normCode)
      && norms.push({
        ...norm,
        // @ts-ignore
        name: NormCodeToNameMap.get(norm.normCode),
        pass: norm.pass,
        // @ts-ignore
        ordering: NormOrderingFromCodeMap.get(norm.normCode) + (norm.pass ? 100 : 0),
        frames,
      });
  });

  norms.sort((a, b) => a.ordering - b.ordering);

  const onRowExpanded = (index: number) => {
    // eslint-disable-next-line no-console
    console.log(index);
    //  todo: hide all inactive
  };

  return (
    <NormCardWrapper>
      <AssessmentSeciontTitle top>
        Performance Assessment
      </AssessmentSeciontTitle>
      <NormTitle>
        <LeftColumn />
        <RightColumn>
          <Label>OUT OF NORM</Label>
          <Label>WITHIN NORM</Label>
        </RightColumn>
      </NormTitle>
      {norms.map(
        (norm, index) => typeof norm.pass === 'boolean'
          && norm.pass !== null && (
            <NormRow
              key={`${norm.name}_${norm.ordering}`}
              norm={norm}
              expand={expand}
              onExpand={() => onRowExpanded(index)}
            >
              <LeftColumn>{norm.name}</LeftColumn>
              <RightColumn>
                <Fail fail={typeof norm.pass === 'boolean' && !norm.pass} />
                <Pass pass={typeof norm.pass === 'boolean' && norm.pass} />
              </RightColumn>
            </NormRow>
        ),
      )}
    </NormCardWrapper>
  );
};

export default NormSummary;

const NormCardWrapper = styled.div`
  width: 100%;
  margin: 30px auto 0 auto;
  background-color: ${mediumGrey.string()};
  border-radius: 3px;
  max-height: 770px;
  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const Norm = styled.div`
  display: flex;
  line-height: 60px;
  border-top: 1px solid ${lightGrey.string()};
  font-size: 16px;
`;

const NormTitle = styled(Norm)`
  color: ${white.string()};
  font-size: 12px;
  line-height: 30px;
  border-top: 0;
  margin-top: -30px;
  max-width: 100%;
`;

const LeftColumn = styled.div`
  min-width: 60%;
  text-align: left;
  padding-left: 20px;
`;

const RightColumn = styled.div`
  width: 40%;
  display: flex;
  justify-content: space-around;
`;

const Checkmark = styled.div`
  & {
    display: inline-block;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    margin-top: 22px;
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
    transform: rotate(45deg);
  }

  &:before {
    content: "";
    position: absolute;
    width: 3px;
    height: 9px;
    background-color: #fff;
    left: 11px;
    top: 6px;
  }
  &:after {
    content: "";
    position: absolute;
    width: 3px;
    height: 3px;
    background-color: #fff;
    left: 8px;
    top: 12px;
  }
`;

const Warning = styled.div`
  & {
    width: 0;
    height: 0;
    margin-top: 22px;
    border-left: 13px solid transparent;
    border-right: 13px solid transparent;
    border-bottom-width: 19px;
    border-bottom-style: solid;
  }
  &:after {
    content: "!";
    color: white;
    position: absolute;
    margin-left: -3px;
    margin-top: -19px;
  }
`;

const Fail = styled(Warning)<{ fail: boolean }>`
  border-bottom-color: ${props => (props.fail ? gradeLevel5.string() : '')};
  visibility: ${props => (props.fail ? 'inherit' : 'hidden')};
`;

const Pass = styled(Checkmark)<{ pass: boolean }>`
  background-color: ${props => (props.pass ? gradeLevel9.string() : '')};
  visibility: ${props => (props.pass ? 'inherit' : 'hidden')};
`;

const AssessmentSeciontTitle = styled(SectionTitle)`
  position: inherit;
`;

const Label = styled.div`
  z-index: -1;
`;
