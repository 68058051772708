import { Annotations, Shape } from 'plotly.js';
import { gradeLevel5, gradeLevel8, lightGrey } from '../../../theme/palette';

export enum NormSummaryAnnotationType {
  Point = 'point',
  Box = 'box',
  BoxText = 'box-text',
  Line = 'line',
  Zone = 'zone',
  Metadata = 'metadata'
}

export interface AnnotationBase {
  type: NormSummaryAnnotationType
}

export type NormSummaryPointAnnotation = AnnotationBase & {
  type: NormSummaryAnnotationType.Point;
  label: string;
  x: string;
  y: number;
  color?: string;
};


export type NormSummaryThreshold = {
  x0: string;
  x1: string;
  y0: number;
  y1: number;
};

export type NormSummaryBoxAnnotation = AnnotationBase & NormSummaryThreshold & {
  type: NormSummaryAnnotationType.Box;
  text: NormSummaryBoxTextAnnotation[];
};

export type NormSummaryBoxTextAnnotation = AnnotationBase & {
  color: string;
  label: string;
  label_angle: number;
  location1: string; // 'top:right'
  location2: NormSummaryBoxTextAnnotationLocation;
};

export type NormSummaryZoneAnnotation = AnnotationBase & {
  type: NormSummaryAnnotationType.Zone;
  x0: string;
  x1: string;
  text: NormSummaryBoxTextAnnotation[];
};

export type NormSummaryLineAnnotation = AnnotationBase & {
  type: NormSummaryAnnotationType.Line;
  label: string;
  x: string;
};

export type MetadataAnnotation = AnnotationBase & {
  type: NormSummaryAnnotationType.Metadata,
  color: string,
  normName: string,
  pass: boolean,
  flightParameter: string | string[]
  scaledTimestampStart: string,
  scaledTimestampEnd: string
};

export type NormSummaryBoxTextAnnotationLocation = 'inside' | 'outside';

export const normSummaryAnnotationColorMap: Record<string, string> = {
  lightgrey: lightGrey.toString(),
  green: gradeLevel8.toString(),
  amber: gradeLevel5.toString(),
};

export type NormSummaryAnnotationHandlerReturnType = {
  annotations?: Partial<Annotations>[];
  shapes?: Partial<Shape>[];
} | null;

export type NormSummaryAnnotation =
  | NormSummaryPointAnnotation
  | NormSummaryBoxAnnotation
  | NormSummaryZoneAnnotation
  | NormSummaryLineAnnotation
  | AnnotationBase;
