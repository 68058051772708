import { TYPE_PLOTLY, TYPE_SUMMARY, InsightType, VisualizationDefinitionType, VisualizationHandlerType } from '../constants';
import getDefaultOptions from '../../plotly';

const getDefaultVisualizations: VisualizationHandlerType<InsightType> = (
  activity,
  data,
  markers,
  metaData,
) => {
  const visualizations: VisualizationDefinitionType[] = [];
  if (data.insight && data.insight.summary && data.insight.summary.payload !== null) {
    const exists = data.insight.summary.payload.some(a => a.score !== null);
    if (exists) {
      visualizations.push({
        type: TYPE_SUMMARY,
        data: data.insight.summary,
      });
    }
  }

  visualizations.push(
    {
      type: TYPE_PLOTLY,
      data: getDefaultOptions(activity, data.frames, markers, metaData),
    },
  );

  return visualizations;
};

export default getDefaultVisualizations;
