export type FrameType = {
  id: number,
  predictedActivityCode?: string,
  timestamp: string,
  data: {
    eng_1N1: number,
    eng_2N1: number,
    v2: number,
    vapp: number,
    vref: number,
    altMsl: number,
    radalt: number,
    cas: number,
    gndSpeed: number,
    pitch: number,
    roll: number,
    hdgTrue: number,
    roc: number,
    nr: number,
    [key: string]: boolean | number | string,
    colPosPilot: number,
    accLatBody: number,
    whlPosPilot: number,
    rudPedPosPilot: number,
    rwyContaminantType: number,
    massAc: number,
    flapLeverPos: number
  },
  activeMalfunctions: string[],
  weatherConditions: string[],
};

export type FetchFramesProps = {
  payload: {
    runId: number,
    activityCode: string | null,
    activityId: number | null,
    fromTimestamp: string,
    toTimestamp: string,
  },
};

export type FetchFramesType = Generator<any, FrameType[], any>;

export const RUNWAY_REFERENCE_HEIGHT = 35;
export const INITAL_PITCH_REFERENCE = 13;
