import * as React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router';
import { useSelector } from 'react-redux';
import { LiveTrainingProps } from './index';

import config from '../../../config';
import { sessionOverview } from '../../../utils/routes';
import Header from '../../../ui/headers/Header';
import Loading from '../../../ui/Loading';
import { FullSessionType, SessionRunType } from '../../constants';
import LessonPlan from '../../../activities/components/LessonPlan';
import { VideoDemoPage } from '../../../demo/pace-leonardo/video';
import { getTrainingCenter } from '../../../dashboard/selectors';

type Props = RouteComponentProps<{ runId: string, sessionId: string }>
& Omit<LiveTrainingProps, 'runs'>
& { runs: SessionRunType[] };

const LiveTraining = ({
  onComponentMounted,
  currentRunId,
  history,
  onEndRun,
  onDone,
  match,
  runs,
  simulatorStatus,
  currentSession,
}: Props) => {
  const isLoading = !currentRunId;
  const [pilotFlying, setPilotFlying] = React.useState<string | null>(null);
  const [pilotMonitoring, setPilotMonitoring] = React.useState<string | null>(null);

  const { trainingCenter } = useSelector(getTrainingCenter);

  React.useEffect(() => {
    setPilotFlying(null);
    setPilotMonitoring(null);

    const sessionId = parseInt(match.params.sessionId, 10);
    const runId = parseInt(match.params.runId, 10);

    onComponentMounted(sessionId, runId);
  }, [match.params.runId, match.params.sessionId, onComponentMounted]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const redirectToSessionOverview = () => history.push(sessionOverview(parseInt(match.params.sessionId, 10)));
  React.useEffect(() => {
    if (currentRunId && runs && runs.length) {
      const currentRun = runs.find((run: SessionRunType) => run.id === currentRunId);
      if (currentRun && currentRunId === +match.params.runId && currentRun.endedAt) {
        // eslint-disable-next-line no-alert
        alert('Current run already ended.');
        redirectToSessionOverview();
      }
    }
  }, [currentRunId, runs, match.params.runId, redirectToSessionOverview]);

  const handleEndSessionRun = () => {
    if (!currentRunId) {
      return;
    }

    // eslint-disable-next-line no-alert
    if (window.confirm('Are you sure you want to end this session?')) {
      onDone();
      onEndRun(
        currentRunId,
        () => redirectToSessionOverview(),
      );
    }
  };

  const handleSetPilotFlying = (flying: string) => setPilotFlying(flying);
  const handleSetPilotMonitoring = (monitoring: string) => setPilotMonitoring(monitoring);

  const currentRun = runs.find((run: SessionRunType) => run.id === currentRunId);

  if (pilotFlying === null && currentRun && currentRun.pilotFlying) {
    handleSetPilotFlying(currentRun.pilotFlying.name);
  }

  if (pilotMonitoring === null && currentRun && currentRun.pilotMonitoring) {
    handleSetPilotMonitoring(currentRun.pilotMonitoring.name);
  }

  const pageTitle = 'name' in currentSession
    // @ts-ignore
    ? `${currentSession.name} | ${config.appName}`
    : config.appName;
  const courseName = 'courseName' in currentSession
    // @ts-ignore
    ? currentSession.courseName.toUpperCase()
    : '';
  const sessionName = 'lessonPlanName' in currentSession
    // @ts-ignore
    ? currentSession.lessonPlanName.toUpperCase()
    : '';

  const session = currentSession as unknown as FullSessionType;

  return (
    <>
      <Helmet>
        <title>
          {pageTitle}
        </title>
      </Helmet>
      <Header showEndSession={false} onEndSessionRun={handleEndSessionRun} simulatorStatus={simulatorStatus} minHeight={trainingCenter === 'PACE' ? '58px' : undefined} />
      {trainingCenter === 'PACE' ? (
        <VideoDemoPage session={session} onEndSession={handleEndSessionRun} />
      ) : (
        <>
          <SessionDetails>
            <Course>{courseName}</Course>
            <Description>{sessionName}</Description>
            <TraineeInfo>
              <Trainee>
                <Name data-pilot-seat="flying">{pilotFlying}</Name>
                <Description>PILOT FLYING</Description>
              </Trainee>
              {pilotMonitoring && (
              <Trainee>
                <Name data-pilot-seat="monitoring">{pilotMonitoring}</Name>
                <Description>PILOT MONITORING</Description>
              </Trainee>
              )}
            </TraineeInfo>
          </SessionDetails>

          {isLoading ? <Loading /> : <LessonPlan onEndSessionRun={handleEndSessionRun} />}
        </>
      )}
    </>
  );
};

export default LiveTraining;

const SessionDetails = styled.div`
  background-color: ${props => props.theme.background.string()};
  position: fixed;
  top: 105px;
  height: 173px;
  width: 100%;
  z-index: 11;
  margin: auto;
  text-align: center;
  border-bottom: 3px solid #303030;
`;

const Course = styled.div`
  font-size: 24px;
  line-height: 28px;
  margin-top: 15px;
  margin-bottom: 16px;
`;

const Description = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: #9C9C9C;
`;

const TraineeInfo = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
`;

const Trainee = styled.div`
  font-size: 16px;
  line-height: 36px;
`;

const Name = styled.div`
  height: 36px;
`;
