import { TakeoffInsightType, TYPE_PLOTLY, TYPE_SUMMARY, VisualizationHandlerType } from '../constants';
import getTakeoffPlotOptions from '../../plotly/plotOptions/takeoff';
import getHeightVelocityPlotOptions from '../../plotly/heightVelocity';
import getSIDPlotOptions from '../../plotly/SID';

// @ts-ignore
const getTakeoffVisualizations: VisualizationHandlerType<TakeoffInsightType> = (
  activity,
  data,
  markers,
  metaData,
) => {
  const visualizations = [];
  if (data.insight && data.insight.summary && data.insight.summary.payload !== null) {
    const exists = data.insight.summary.payload.some(a => a.score !== null);
    if (exists) {
      visualizations.push({
        type: TYPE_SUMMARY,
        data: data.insight.summary,
      });
    }
  }

  visualizations.push(
    {
      type: TYPE_PLOTLY,
      data: getTakeoffPlotOptions(activity, data, markers, metaData),
      page: 'Takeoff Performance',
    },
    {
      type: TYPE_PLOTLY,
      data: getHeightVelocityPlotOptions(data.frames),
      page: 'Height-Velocity',
    },
  );

  if (data.insight && data.insight.departure) {
    visualizations.push({
      type: TYPE_PLOTLY,
      // @ts-ignore
      data: getSIDPlotOptions(data.insight.departure),
    });
  }

  return visualizations;
};

export default getTakeoffVisualizations;
