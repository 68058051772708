import * as React from 'react';
import styled from 'styled-components';

const Divider = styled.div<{color?: string; width?: number; height?: number}>`
    position: relative;
    background-color: ${props => props.color || 'inherit'};
    width: ${props => (props.width ? `${props.width}px` : '100%')};
    height: ${props => (props.height ? `${props.height}px` : '1px')};
    opacity: 0.8;
`;

export interface HorizontalDividerProps extends React.HTMLAttributes<HTMLDivElement> {}

export default function HorizontalDivider(props:HorizontalDividerProps) {
  return <Divider {...props} />;
}
