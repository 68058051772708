import { GeneralStoreType } from '../store/constants';

export const activeRunIds = (
  state: GeneralStoreType,
) => state.getIn(['session', 'currentEquipmentActiveRunIds']).toArray() || [];

export const currentSessionId = (
  state: GeneralStoreType,
): number | null => state.getIn(['session', 'currentSession', 'id']) || null;

export const currentSessionActiveRunId = (
  state: GeneralStoreType,
): number | null => state.getIn(['session', 'currentSession', 'activeRunId']) || null;

export const currentEquipmentDeviceId = (
  state: GeneralStoreType,
) => state.getIn(['session', 'currentSession', 'equipmentDeviceId']);
