import React from 'react';
import { camelCase } from 'lodash';
import Plotly from '../../../ui/Plotly';
import { getPlotlyData } from './utils';
import { NormType } from '../../visualization/constants';
import { FrameType } from '../../../frames/constants';
import { MetadataAnnotation, NormSummaryAnnotation, NormSummaryAnnotationType } from '../annotations/types';

const NormSummaryGraph = (props: {
  norm: NormType & { frames: FrameType[]; annotation: NormSummaryAnnotation[] };
}) => {
  const {
    norm: {
      annotation: annotations,
      frames,
    },
  } = props;

  const metadata: MetadataAnnotation | undefined = annotations.find(x => x.type === NormSummaryAnnotationType.Metadata) as MetadataAnnotation;

  if (!metadata) {
    console.log(`Metadata annotation is missed in ${JSON.stringify(annotations)}}`);
    return (<> </>)
  }

  const flightParam = Array.isArray(metadata.flightParameter)
    ? metadata.flightParameter.map(x => camelCase(x))
    : camelCase(metadata.flightParameter);

  const [startTime, endTime] = [
    new Date(metadata.scaledTimestampStart).getTime(),
    new Date(metadata.scaledTimestampEnd).getTime(),
  ];

  const xValues = frames
    .map(x => x.timestamp)
    .filter(x => {
      const time = new Date(x).getTime();
      return time >= startTime && time <= endTime;
    });

  const startIndex = frames.map(x => x.timestamp).indexOf(xValues[0]);
  const endIndex = frames
    .map(x => x.timestamp)
    .indexOf(xValues[xValues.length - 1]);

  const getYValues = (param: string) => frames.map(x => x.data[param]).slice(startIndex, endIndex) as number[];

  const yValues: any = {};
  if (Array.isArray(flightParam)) {
    flightParam.forEach(x => {
      const y = getYValues(x);
      yValues[x] = y;
    });
  } else {
    yValues[flightParam] = getYValues(flightParam);
  }

  const plotly = getPlotlyData({
    x: xValues,
    y: yValues,
    annotations,
  });

  return (
    <>
      <Plotly {...plotly} />
    </>
  );
};

export default NormSummaryGraph;
