import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import LessonPlan from './LessonPlan';
import { GeneralStoreType } from '../../../store/constants';

const mapStateToProps = (state: GeneralStoreType) => {
  const activities = state.get('activities');
  return {
    lessonPlan: activities.get('lessonPlan'),
    currentRunId: activities.get('currentRunId'),
  };
};

export default connect(
  mapStateToProps,
  // @ts-ignore
)(withImmutablePropsToJS<any>(LessonPlan));
