import React from 'react';
import { getWindBox } from '../Landing/utils';

import RunwaySVG from '../Runway';
import { RunwaySVGProps } from '../Runway/types';
import CasMarkers from '../CasMarkers';
import { CasMarkersProps } from '../CasMarkers/types';
import { NormMarkersProps } from '../Norms/types';
import NormsSvg from '../Norms';
import {
  getRejectedTakeoffLatDevLine,
  getRejectedTakeoffNormsMarkers,
  getV1Label,
} from './utils';
import { getNormalizers } from '../Runway/utils';

// @ts-ignore
const RejectedTakeoffSVG = data => {
  const {
    insight: {
      departure: {
        runwayName,
        oppositeRunwayName,
        runwayLength,
        runwayWidth,
        casMarkers,
        windSpeed,
        windDirection,
        relWindDirection,
        v1,
        latDev,
        norms,
        airportCode,
      },
    },
    frames,
  } = data;

  const {
    normalizeFactor,
    normalizeFactorX,
    getPosition,
  } = getNormalizers(
    runwayLength,
    runwayWidth,
  );

  //  NORMS
  const normsSvg = getRejectedTakeoffNormsMarkers(
    norms,
    normalizeFactor,
    frames,
  );

  const runwayProps: RunwaySVGProps = {
    runwayName,
    oppositeRunwayName,
    normalizedBottomThreshold: 0,
    normalizedTopThreshold: 0,
    normalizeFactor,
    runwayLength,
    airportCode,
  };

  const casMarkersProps: CasMarkersProps = {
    offset: 0,
    markers: casMarkers,
    getPosition,
    normalizedRunwayLength: runwayLength * normalizeFactor,
    topThreshold: 0,
  };

  const normMarkersProps: NormMarkersProps = {
    offset: 0,
    markers: normsSvg,
    getPosition,
    normalizedRunwayLength: runwayLength * normalizeFactor,
  };

  return (
    <RunwaySVG {...runwayProps}>
      {/* CAS markers */}
      <CasMarkers {...casMarkersProps} />

      {/* V1 */}
      {getV1Label(v1)}

      {/* Norms */}
      <NormsSvg {...normMarkersProps} />

      {/* Runway and Aircraft Conditions */}
      {getWindBox(windSpeed, windDirection, relWindDirection, frames[0].data, !!norms.autobrake)}

      {/* lat dev */}
      {getRejectedTakeoffLatDevLine(
        latDev,
        normalizeFactor,
        normalizeFactorX,
        0,
      )}
    </RunwaySVG>
  );
};

export default RejectedTakeoffSVG;
