import { Annotations, Shape } from 'plotly.js';
import { secondary, accentBlue, tertiary, white } from '../../../theme/palette';
import { getShortName } from '../../simData';
import { NormSummaryBoxAnnotation, NormSummaryLineAnnotation, NormSummaryPointAnnotation, NormSummaryZoneAnnotation } from '../annotations/types';
import {
  NormSummaryAnnotation,
  NormSummaryAnnotationType,
} from '../annotations/types';
import {
  getBoxAnnotation,
  getLineAnnotation,
  getPointAnnotation,
  getZoneAnnotation,
} from './annotations';

const config = {
  responsive: true,
  displayModeBar: false,
};
const style = {
  height: '100%',
  width: '100%',
};

const plotColors = [
  accentBlue.string(),
  tertiary.string(),
  secondary.string(),
];

const getAnnotations = (
  annotationsData: NormSummaryAnnotation[],
  yValues: number[],
): { annotations: Partial<Annotations>[]; shapes: Partial<Shape[]> } => {
  const entries = annotationsData
    .map((annotation, index) => {
      switch (annotation.type) {
        case NormSummaryAnnotationType.Point:
          return getPointAnnotation(annotation as NormSummaryPointAnnotation, index);
        case NormSummaryAnnotationType.Box:
          return getBoxAnnotation(annotation as NormSummaryBoxAnnotation, yValues);
        case NormSummaryAnnotationType.Zone:
          return getZoneAnnotation(annotation as NormSummaryZoneAnnotation, yValues);
        case NormSummaryAnnotationType.Line:
          return getLineAnnotation(annotation as NormSummaryLineAnnotation, yValues);
        default:
          return null;
      }
    })
    .filter(entry => !!entry);

  const annotations = entries
    .map(val => val?.annotations)
    .flat()
    .filter(val => !!val) as Partial<Annotations>[];

  const shapes = entries
    .map(val => val?.shapes)
    .flat()
    .filter(val => !!val) as Partial<Shape[]>;

  return {
    annotations,
    shapes,
  };
};

const addAnnotations = (
  annotationsData: NormSummaryAnnotation[],
  yValues: number[],
  flightParam: string
): Partial<Plotly.Layout> => {
  const { annotations, shapes } = getAnnotations(annotationsData, yValues);
  return {
    autosize: true,
    clickmode: 'none',
    dragmode: false,
    paper_bgcolor: 'transparent',
    plot_bgcolor: 'transparent',
    showlegend: true,
    xaxis: {
      showgrid: false,
    },
    yaxis: {
      showgrid: false,
      showline: true,
      title: getShortName(flightParam),
    },
    font: {
      color: white.string(),
    },

    //  @ts-ignore
    shapes: [...shapes],
    annotations,
  };
};

const getData = (
  flightParam: string,
  x: string[],
  y: number[],
  index: number,
  showlegend = false,
) => ({
  x,
  y,
  name: getShortName(flightParam),
  line: {
    color: plotColors[index],
    width: 4,
  },
  mode: 'lines',
  type: 'scatter',
  showlegend,
});

export const getPlotlyData = ({
  x,
  y,
  annotations,
}: {
  x: string[];
  y: Record<string, number[]>;
  annotations: NormSummaryAnnotation[];
}) => {
  const params = Object.keys(y);
  const hasMultipleParams = params.length > 1;
  const data = params.map((param, index) => getData(param, x, y[param], index, hasMultipleParams));
  const yValues = Object.values(y).flat();

  const flightParam = hasMultipleParams ? '' : params[0];

  const layout = addAnnotations(annotations, yValues, flightParam);

  return {
    data,
    layout,
    config,
    style,
    useResizeHandler: true,
  };
};
