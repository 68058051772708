import * as React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Column, Container, Row } from '../components/grid';
import { BUTTON_BACKGROUND_GRADIENT, BUTTON_BORDER_DISABLED, Button } from '../../../ui/Button';
import CardCarousel from './CalculatingCarousel';
import ClassifierProgress from '../../../sessionList/components/SessionOverview/ClassifierProgress';
import { FullSessionType } from '../../../session/constants';

const useIncrementingDecimal = (incrementAmount = 0.01, interval = 100, sessionId?: string | number) => {
  const [reachedOne, setReachedOne] = React.useState<boolean>(false);
  const [decimal, setDecimal] = React.useState<number>(0);

  React.useEffect(() => {
    // Load previous session IDs that reached 1 from localStorage
    const storedSessions = JSON.parse(localStorage.getItem('calculated') || '[]');

    if (sessionId && storedSessions.includes(sessionId)) {
      setReachedOne(true);
      setDecimal(1);
      return () => {};
    }

    const buffer = 0.99;
    const timer = setInterval(() => {
      setDecimal(prevDecimal => {
        const newDecimal = (prevDecimal + incrementAmount) % 1;
        if (prevDecimal >= buffer || newDecimal >= buffer || reachedOne) {
          clearInterval(timer);
          if (sessionId) {
            // Update localStorage with the current session ID
            const updatedSessions = [...storedSessions, sessionId];
            localStorage.setItem('calculated', JSON.stringify(updatedSessions));
          }

          setReachedOne(true);
          return 1;
        }

        return newDecimal;
      });
    }, interval);
    return () => clearInterval(timer);
  }, [incrementAmount, interval, sessionId, reachedOne]);

  return [decimal, reachedOne] as const;
};

export interface CalculatingPageProps {
  session: FullSessionType
  onReview: () => void;
}
export default function CalculatingPage(props: CalculatingPageProps) {
  const { session, onReview } = props;

  const infoCardsInterval = 1 * 60 * 1000;
  const totalMilliseconds = 3 * 60 * 1000; // 3 minutes in milliseconds
  const numberOfIncrements = 100;
  const interval = totalMilliseconds / numberOfIncrements;
  const [mockProgressIncrement] = useIncrementingDecimal(0.01, interval, session.id);

  const cards = [
    { title: 'Detecting maneuvers', description: '' },
    { title: '', description: 'Maneuver detection completed' },

    { title: 'Analyzing pilot\'s performance', description: '' },
    { title: '', description: 'Performance assessment completed' },

    { title: 'Inferring pilot\'s competencies', description: '' },
    { title: '', description: 'Competency inference completed' },
  ];

  const attrs = {
    pilot: session.trainees.pilotFlying.name,
    lesson: session.lessonPlanName,
    date: moment(session.scheduledFor).format('MMMM Do, h:mm A'),
    aircraft: session.equipment?.aircraftDetails?.aircraft?.[0].designator,
    device: session.equipment?.aircraftDetails?.aircraft?.[0].name,
  };

  const reviewDisabled = mockProgressIncrement < 1;
  const reviewButtonVariant = reviewDisabled
    ? BUTTON_BORDER_DISABLED
    : BUTTON_BACKGROUND_GRADIENT;

  return (
    <Body>
      <Container>
        <Row spacing={2} style={{ paddingBottom: 32 }}>
          <Column xs={12}>
            <ImageBannerBg>
              <ImageInfoBar>
                M-346 | ULTD_M-346
              </ImageInfoBar>
            </ImageBannerBg>
          </Column>
        </Row>
      </Container>
      <Container>
        <Row>
          <Column>
            <Item>
              <SessionTitle>
                {session.lessonPlanName}
              </SessionTitle>
            </Item>
          </Column>
        </Row>
      </Container>
      <Container>
        <Row>
          <Column xs={12}>
            <Item>
              <AttrributesLabel label="Student: ">
                {attrs.pilot}
              </AttrributesLabel>
              <AttrributesLabel label="Date scheduled: ">
                {attrs.date}
              </AttrributesLabel>
              <AttrributesLabel label="Aircraft Type: ">
                {attrs.aircraft}
              </AttrributesLabel>
            </Item>
          </Column>

          <Column xs={12}>
            <Item>
              <ClassifierProgress progress={mockProgressIncrement} width={900} />
            </Item>
          </Column>

          <Column xs={12}>
            <Item>
              <CardCarousel cards={cards} interval={infoCardsInterval} stop={mockProgressIncrement >= 1} />
            </Item>
          </Column>

          <Column>
            <Item>
              <Button
                width="304px"
                height="64px"
                onClick={onReview}
                disabled={reviewDisabled}
                variation={reviewButtonVariant}
              >
                REVIEW
              </Button>
            </Item>
          </Column>
        </Row>
      </Container>
    </Body>
  );
}

const ImageBannerBg = styled.div`
  display: flex;
  max-width: 436px;
  padding-top: 141px;
  margin: auto;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border-radius: 8px;
  background-image: url(/images/m346-fa.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;
const ImageInfoBar = styled.div`
  position: relative;
  bottom: -83%;
  display: flex;
  width: 100%;
  padding: 12px;
  justify-content: center;
  align-items: center;
  background: rgba(17, 19, 34, 0.40);
  backdrop-filter: blur(16px);
  border-bottom-right-radius 8px;
  border-bottom-left-radius 8px;
`;

const Item = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  gap: 16px;
`;

const AttrributesLabel = styled.span<{label: string}>`
  :before {
    font-weight: 700;
    content: "${props => props.label}";
  }
  align-self: stretch;
  color: #DDD;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 15.4px */
`;

const SessionTitle = styled.h2`
  color: #FFF;
  font-family: Roboto;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 30.8px */
`;

const Body = styled.div`
  margin-top: 113px;
  padding-bottom: 2rem;
`;
